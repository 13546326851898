/* eslint-disable react/prop-types */
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import React, { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const AnnouncementLocation = ({ locations }) => {
  console.log(locations);
  const [announcementLocationValue, setAnnouncementLocationValue] = useState([]);
  const [allLocations, setAllLocations] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setAnnouncementLocationValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <input
        name="announcement_locations"
        type="hidden"
        value={JSON.stringify({ locations: announcementLocationValue })}
      />
      <input name="all_locations" type="hidden" value={allLocations} />
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          disabled={allLocations}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={announcementLocationValue}
          onChange={handleChange}
          input={<OutlinedInput label="Locations" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {locations.map((location) => (
            <MenuItem key={location} value={location}>
              <Checkbox checked={announcementLocationValue.indexOf(location) > -1} />
              <ListItemText primary={location} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            onClick={() => {
              setAllLocations(!allLocations);
            }}
          />
        }
        label="All Locations"
      />
    </div>
  );
};

export default AnnouncementLocation;
