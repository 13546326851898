import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

const hardCodedOptions = [
  { name: "REPORT FOR GUEST", id: null },
  { name: "UNKNOWN CLIENT (UNLINKED)", id: null }
];

function ClientSearchInputAutocomplete({ selected_client_name = "", options }) {
  const [selectedClientName, setSelectedClientName] = useState("");

  const inputRef = useRef(null);
  const dropdownContainerRef = useRef(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const filteredOptions = useMemo(() => {
    return options.filter((option) => {
      return option.name.toLowerCase().includes(selectedClientName.toLowerCase());
    });
  }, [options, selectedClientName]);

  const filteredHardCodedOptions = useMemo(() => {
    return hardCodedOptions.filter((option) => {
      return option.name.toLowerCase().includes(selectedClientName.toLowerCase());
    });
  }, [selectedClientName]);

  useEffect(() => {
    setSelectedClientName(selected_client_name);
  }, [selected_client_name]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (inputRef.current && inputRef.current.contains(event.target)) ||
        (dropdownContainerRef.current && dropdownContainerRef.current.contains(event.target))
      ) {
        setIsListVisible(true);
      } else {
        setIsListVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, dropdownContainerRef]);

  function updateUrlParams(clientName) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("client_name", clientName);
    window.location.search = currentUrlParams.toString();
  }

  function handleClick() {
    updateUrlParams(selectedClientName);
  }

  function handleDropdownClick(optionName) {
    setSelectedClientName(optionName);
    setIsListVisible(false);
    updateUrlParams(optionName);
  }
  return (
    <div className="client-search-input-autocomplete">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <input
          ref={inputRef}
          autoComplete="off"
          id="selected_client_name"
          className="input"
          type="text"
          placeholder="Search by Client name"
          value={selectedClientName}
          onChange={(e) => setSelectedClientName(e.target.value)}
        />
        <button type="submit" className="search-btn" onClick={handleClick}>
          <i className="fas fa-search"></i>
        </button>
      </form>

      {selectedClientName && (
        <label
          className="clear-label"
          onClick={() => {
            setSelectedClientName("");
            updateUrlParams("");
          }}
        >
          <i className="fas fa-times"></i> Clear
        </label>
      )}
      {isListVisible && (filteredOptions.length > 0 || filteredHardCodedOptions.length > 0) && (
        <div ref={dropdownContainerRef} className="dropdown-container">
          <ul>
            {filteredHardCodedOptions.map((option) => {
              return (
                <li
                  key={`${option.id} ${option.name}`}
                  onClick={() => {
                    handleDropdownClick(option.name);
                  }}
                >
                  {option.name}
                </li>
              );
            })}
            {filteredOptions.length > 0 && filteredHardCodedOptions.length > 0 && (
              <li className="divider"></li>
            )}

            {filteredOptions.map((option) => {
              console.log("option", option);
              return (
                <li
                  key={`${option.id} ${option.name}`}
                  onClick={() => {
                    handleDropdownClick(option.name);
                  }}
                >
                  {option.name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

ClientSearchInputAutocomplete.propTypes = {
  selected_client_name: PropTypes.string,
  selected_client_id: PropTypes.number,
  options: PropTypes.array
};
export default ClientSearchInputAutocomplete;
