import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { formatDateTimeByString } from "../../timeUtils";
import axios from "axios";
import useAsyncCallback from "../../hooks/useAsyncCallback";
import TableViewContextProvider, { useTableViewContext } from "./TableViewContext";
import TableViewPopup from "./table_view_popup";
import { RefreshShiftLogsContext } from "./RefreshShiftLogsContext";
import ReadMore from "../read_more";

const TableView = ({ date, shiftNum, location }) => {
  const isShowingAllShift = shiftNum === "All Shifts";
  const {
    loading,
    error,
    data: formattedShiftLogs,
    execute: refresh
  } = useAsyncCallback(async () => getShiftLogsGroupByClient(date, shiftNum, location), []);

  const [hideImageUrls, setHideImageUrls] = useState([]);

  const onImageLoadError = (url) => {
    setHideImageUrls([...hideImageUrls, url]);
  };

  return (
    <TableViewContextProvider pageParams={{ date, shiftNum, location }}>
      <RefreshShiftLogsContext.Provider value={refresh}>
        <div className="table-container">
          {loading && <div>loading...</div>}
          {error && <div>Error: {error.message}</div>}
          {!loading && (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Reg#</th>
                  <th>Client Name</th>
                  <th>Client Detail</th>
                  <th>Shift Number</th>
                  <th>Shift Note (What happened?)</th>
                  <th>Care Plan</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {formattedShiftLogs.map((shiftLog, index) => {
                  return (
                    <Fragment key={index}>
                      <tr id={`${replaceWhitespaceWithUnderscore(shiftLog.client_name)}`}>
                        <td rowSpan={isShowingAllShift ? 3 : 1}>{shiftLog.client_id}</td>
                        <td rowSpan={isShowingAllShift ? 3 : 1}>{shiftLog.client_name}</td>
                        <td rowSpan={isShowingAllShift ? 3 : 1}>
                          <div>{shiftLog.client_detail.pronoun}</div>
                          <div>{shiftLog.client_detail.healthcard_number}</div>
                          <div>{shiftLog.client_detail.dob}</div>
                          {shiftLog.client_detail.avatar_url &&
                            !hideImageUrls.includes(shiftLog.client_detail.avatar_url) && (
                              <img
                                src={shiftLog.client_detail.avatar_url}
                                className="table-container-avatar_url"
                                onError={() => onImageLoadError(shiftLog.client_detail.avatar_url)}
                              />
                            )}
                        </td>
                        {(isShowingAllShift || shiftNum === "1st") && (
                          <>
                            <TableTd rowShiftNum="1st" shiftLog={shiftLog} />
                          </>
                        )}
                        {shiftNum === "2nd" && (
                          <>
                            <TableTd rowShiftNum="2nd" shiftLog={shiftLog} />
                          </>
                        )}
                        {shiftNum === "3rd" && (
                          <>
                            <TableTd rowShiftNum="3rd" shiftLog={shiftLog} />
                          </>
                        )}
                      </tr>
                      {isShowingAllShift && (
                        <tr>
                          <TableTd rowShiftNum="2nd" shiftLog={shiftLog} />
                        </tr>
                      )}
                      {isShowingAllShift && (
                        <tr>
                          <TableTd rowShiftNum="3rd" shiftLog={shiftLog} />
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
          <TableViewPopup />
        </div>
      </RefreshShiftLogsContext.Provider>
    </TableViewContextProvider>
  );
};
function TableTd({ rowShiftNum, shiftLog }) {
  const isNewLog = !(shiftLog.shifts[rowShiftNum] && shiftLog.shifts[rowShiftNum].id);
  const action = isNewLog ? "new" : "edit";

  const { state, dispatch } = useTableViewContext();
  const togglePopup = () => {
    dispatch({ type: "action", payload: action });
    dispatch({ type: "selectedRowShiftNum", payload: rowShiftNum });
    dispatch({ type: "selectedShiftLog", payload: shiftLog.shifts[rowShiftNum] });
    dispatch({ type: "selectedClientId", payload: shiftLog.client_id });
    dispatch({ type: "selectedClientName", payload: shiftLog.client_name });
    dispatch({ type: "selectedClientDetail", payload: shiftLog.client_detail });

    dispatch({ type: "popup", payload: !state.popup });
  };

  return (
    <>
      <td>{rowShiftNum}</td>
      <td>
        <div className="table-container-log-description">
          <ReadMore text={shiftLog.shifts[rowShiftNum]?.log_description ?? ""}></ReadMore>
          <div>
            <button
              id={`${replaceWhitespaceWithUnderscore(
                shiftLog.client_name
              )}_shift_${rowShiftNum}_${action}_btn`}
              className={`button is-small ${action == "new" ? "is-primary" : ""} `}
              onClick={togglePopup}
            >
              {action.charAt(0).toUpperCase() + action.slice(1)}
            </button>
          </div>
        </div>
      </td>

      <td>
        <div className="table-container-log-description">
          <ReadMore text={shiftLog.client_detail.care_plan}></ReadMore>
        </div>
      </td>

      <td>
        {shiftLog.shifts[rowShiftNum]
          ? formatDateTimeByString(shiftLog.shifts[rowShiftNum]?.updated_at)
          : ""}
      </td>
    </>
  );
}

TableView.propTypes = {
  date: PropTypes.string.isRequired,
  shiftNum: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};
TableTd.propTypes = {
  rowShiftNum: PropTypes.string.isRequired,
  shiftLog: PropTypes.object.isRequired
};

export default TableView;
async function getShiftLogsGroupByClient(date, shiftNum, location) {
  const res = await axios.post("/shift_logs/group_by_client.json", {
    date,
    shift_num: shiftNum,
    location
  });
  return res.data;
}

function replaceWhitespaceWithUnderscore(str) {
  return str.replace(/\s+/g, "_");
}
