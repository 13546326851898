/* eslint-disable react/no-unknown-property */
import { Dialog, DialogContent } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ToDoListMarkInCompleteContext = createContext();
async function completeTask(id, inCompletedToDoDate, hourlyRecurrenceTime, userInitial, reason) {
  // create a form submit to send to the backend
  try {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");
    const response = await axios.post(
      `/to_do_lists/${id}/incomplete.json`,
      {
        incompleted_to_do_date: inCompletedToDoDate,
        hourly_recurrence_time: hourlyRecurrenceTime,
        user_initial: userInitial,
        reason: reason
      },
      {
        headers: {
          "X-CSRF-Token": csrfToken
        }
      }
    );

    if ((response.status >= 200 && response.status < 300) || response.status === 302) {
      window.location.reload();
    }
  } catch (err) {
    toastr.error("Something went wrong");
  }
}

function ToDoListMarkIncomplete({ id, inCompletedToDoDate, hourlyRecurrenceTime }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    userInitial: "",
    reason: ""
  });

  function sendFormData() {
    completeTask(
      id,
      inCompletedToDoDate,
      hourlyRecurrenceTime,
      formData.userInitial,
      formData.reason
    );
  }

  return (
    <ToDoListMarkInCompleteContext.Provider
      value={{ open, setOpen, formData, setFormData, sendFormData }}
    >
      <button
        className="button mark-as-incomplete-button"
        onClick={() => {
          setOpen(true);
        }}
      >
        <i className="fas fa-not-equal"></i>
        <span>Mark as incomplete</span>
      </button>
      <InCompletePopup />
    </ToDoListMarkInCompleteContext.Provider>
  );
}

function InCompletePopup() {
  const { open, setOpen } = useContext(ToDoListMarkInCompleteContext);
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent className="to-do-incomplete-popup">
        <InCompleteForm />
      </DialogContent>
    </Dialog>
  );
}
function InCompleteForm() {
  const { formData, setFormData, sendFormData } = useContext(ToDoListMarkInCompleteContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    sendFormData();
  };
  return (
    <form className="to-do-incomplete-form">
      <h1 className="title">Mark as incomplete</h1>
      <p>Please enter your initial</p>
      <input
        type="text"
        className="input"
        value={formData.userInitial}
        onChange={(e) => setFormData({ ...formData, userInitial: e.target.value })}
        explorator_test_id={"to_do_list_mark_incomplete_initials_input"}
      />
      <p>Reason</p>
      <textarea
        className="textarea"
        name="reason"
        value={formData.reason}
        onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
        explorator_test_id={"to_do_list_mark_incomplete_reason_input"}
      ></textarea>
      <button
        className="button is-primary submit-to-do"
        onClick={handleSubmit}
        disabled={!formData.userInitial || !formData.reason}
        explorator_test_id={"to_do_list_mark_incomplete_initials_input_submit_button"}
      >
        Confirm
      </button>
    </form>
  );
}

ToDoListMarkIncomplete.propTypes = {
  id: PropTypes.number.isRequired,
  inCompletedToDoDate: PropTypes.string.isRequired,
  hourlyRecurrenceTime: PropTypes.string.isRequired
};
export default ToDoListMarkIncomplete;
