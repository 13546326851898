import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import PropTypes from "prop-types";

const UserSearchAutoComplete = ({ selected_assigned_user_id }) => {
  const [selectedUserId, setSelectedUserId] = useState(selected_assigned_user_id);
  const [isAssignToUser, setIsAssignToUser] = useState(selected_assigned_user_id !== null);
  const [previousSelectedOption, setPreviousSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    axios.get("/user_list.json").then((response) => {
      const list = response.data;
      const mapped_list = list.map((user) => ({ label: user.full_name, value: user.id }));
      if (selected_assigned_user_id) {
        setPreviousSelectedOption(
          mapped_list.find((user) => user.value === selected_assigned_user_id)
        );
      }
      setOptions(mapped_list);
    });
  }, []);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = selectedUserId;
    }
  }, [selectedUserId]);

  return (
    <div className="user-search-autocomplete">
      <div className="row">
        <input
          className="checkbox"
          type="checkbox"
          checked={isAssignToUser}
          onChange={(e) => {
            setIsAssignToUser(e.target.checked);
            console.log(e.target.checked);
            if (!e.target.checked) setSelectedUserId(null);
          }}
        />
        <b>
          <label>Assign to a user</label>{" "}
        </b>
      </div>
      {isAssignToUser && (
        <div className="row">
          {options.length > 0 && (
            <Select
              id="user_search_autocomplete"
              defaultValue={previousSelectedOption}
              options={options}
              isClearable={true}
              onChange={(selectedOption) => {
                setSelectedUserId(selectedOption.value);
              }}
            />
          )}
        </div>
      )}
      <input
        ref={inputRef}
        className="input recur-task"
        type="hidden"
        name="to_do_list[assigned_user_id]"
        id="to_do_list_assigned_user_id"
      ></input>
    </div>
  );
};
UserSearchAutoComplete.propTypes = {
  selected_assigned_user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UserSearchAutoComplete;
