import React, { useState, useEffect, useMemo, useRef } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import PropTypes from "prop-types";
import NativeElementListener from "./native_element_value_listener";

const guestOption = { label: "The report is for guest", value: "GUEST", id: "GUEST" };
const GroupInput = (props) => {
  const { autoComplete } = props.selectProps;
  return (
    <div className="incident_report_client_search_input">
      <components.Group
        {...props}
        explorator_test_id={"client-page-autosearch-input-element"}
        autoComplete={autoComplete}
      />
    </div>
  );
};

GroupInput.propTypes = {
  selectProps: PropTypes.object.isRequired
};

const ClientSearchAutoComplete = (props) => {
  const {
    name,
    selected_client_name,
    selected_client_id,
    is_users_active = "all",
    is_for_guest: isForGuestSelected
  } = props;

  const [selectedClientName, setSelectedClientName] = useState("Unknown Client");
  const [selectedClientId, setSelectedClientId] = useState(selected_client_id);
  const [allClients, setAllClients] = useState([]);
  const clientAutocompleteIdRef = useRef(null);

  // This is only used when listen_location_change_input_id is passed in.
  const [location, setLocation] = useState("");

  const loadClientsAutoCompleteList = (location = "") => {
    let url = "/clients/client_autocomplete_authorized_clients.json";
    if (location) {
      url += `?location=${location}`;
    }
    axios.get(url).then((response) => {
      let filteredClients = response.data.filter((client) => {
        if (is_users_active === "true") return !client.inactive;
        if (is_users_active === "false") return client.inactive;
        if (!is_users_active || is_users_active === "all") return true;

        return false;
      });

      setAllClients(filteredClients);
    });
  };

  const options = useMemo(() => {
    const createClientOption = (client) => {
      const clientName = `${client.first_name} ${client.last_name}`;
      return {
        label: clientName,
        value: clientName,
        id: client.id
      };
    };

    const clientsToDisplay = location
      ? allClients.filter((client) => client.location === location)
      : allClients;

    const clientOptions = clientsToDisplay.map(createClientOption);

    const hardCodedOptions = [guestOption, { label: "Unknown Client", value: "", id: "" }];

    return [
      {
        label: "Hard Coded",
        options: hardCodedOptions
      },
      {
        label: "Dynamic",
        options: clientOptions.sort((a, b) => a.label.localeCompare(b.label))
      }
    ];
  }, [allClients, location]);

  const selectClient = useMemo(() => {
    if (!selectedClientId) {
      return null;
    }
    if (selectedClientId === guestOption.id) {
      return guestOption;
    }
    if (selectedClientId) {
      return allClients.find((allClient) => +allClient.id === +selectedClientId);
    }

    return null;
  }, [selectedClientId, allClients]);

  useEffect(() => {
    if (clientAutocompleteIdRef.current) {
      clientAutocompleteIdRef.current.value = selectClient ? selectClient.id : "";
    }
  }, [selectClient, selectedClientId]);

  useEffect(() => {
    if (selected_client_name) {
      setSelectedClientName(selected_client_name);
    }
    if (isForGuestSelected) {
      setSelectedClientName(guestOption.label);
      setSelectedClientId(guestOption.id);
    }
  }, []);

  useEffect(() => {
    loadClientsAutoCompleteList(location);
  }, [location]);

  const defaultStyles = {
    option: (styles) => ({
      ...styles,
      cursor: "pointer"
    })
  };

  return (
    <div className="client-search-autocomplete">
      {props.listen_location_change_input_id && (
        <NativeElementListener
          listenId={props.listen_location_change_input_id}
          setFieldValue={setLocation}
        />
      )}
      <Select
        key={`${location}`}
        styles={defaultStyles}
        components={{ Group: GroupInput }}
        options={options}
        isClearable={true}
        className="incident_report_client_search"
        value={{ label: selectedClientName, value: selectedClientName }}
        onChange={(selectedOption) => {
          setSelectedClientName(selectedOption?.label || "");
          setSelectedClientId(selectedOption?.id || "");
        }}
        name={name}
      />
      <input
        data-explorator_test_id="todo_list_new_page_client_name_"
        ref={clientAutocompleteIdRef}
        name={"client_autocomplete_id"}
        type={"hidden"}
      />
      <HiddenInput selectClient={selectClient} />
    </div>
  );
};

const HiddenInput = ({ selectClient }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const value = selectClient ? JSON.stringify(selectClient) : "";
    if (inputRef.current) {
      inputRef.current.value = value;
      const event = new Event("change", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  }, [selectClient]);

  /* The hidden input fields are created so other native components can get the selected client data. */
  return <input id="client_autocomplete_selected_client" type="hidden" ref={inputRef} />;
};

export default ClientSearchAutoComplete;

ClientSearchAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  selected_client_name: PropTypes.string,
  selected_client_id: PropTypes.string,
  is_users_active: PropTypes.string,
  listen_location_change_input_id: PropTypes.string,
  is_for_guest: PropTypes.bool
};

HiddenInput.propTypes = {
  selectClient: PropTypes.object || null
};
